import * as React from 'react'
import VisasContainer from '../../components/premium-assesment/visas-tag'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Box } from '@mui/system'
import ContentContainer from '../../components/content-container'
import Header from '../../components/premium-assesment/header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@mui/material'
import BackButton  from '../../components/premium-assesment/back-button'
import VisaCards from '../../components/premium-assesment/visa-cards'
import Expertise from '../../components/premium-assesment/expertise'
import { graphql, Link } from 'gatsby'
import AssessmentResult from '../../components/premium-assessment-result/assessment-result'
import { useFilter, useSelect } from 'react-supabase'


const AssessmentPage = ({ params }) => {

  return(
    <>
    {/* <pre id="json">{JSON.stringify(data, null, 2)}</pre> */}
    <Seo title="Resultado page" />
     <Box
       sx={{
         backgroundColor: (theme) => theme.palette.primary.dark,
         color: (theme) => theme.palette.text.light,
       }}
     >
       <Layout>
       <AssessmentResult visaassessmentId={ params.assessmentId} />
       </Layout>
     </Box>
   </>
  )
}



export default AssessmentPage 
