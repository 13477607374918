import React,{useState} from 'react'
import { useQuery } from 'urql'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useFilter, useSelect } from 'react-supabase'
import { graphql, useStaticQuery } from 'gatsby'
import Requirements  from '../premium-assesment/requirements'
import CardContent from '@mui/material/CardContent';
import Item from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import { Markup } from 'interweave';
import axios from "axios";


const RequirementsResults = (props) => {
  const visaId = props.visaId.toString()
  const assesmentId = props.assesmentId.toString()
  const baseURL =  `https://movigy1.hasura.app/api/rest/selectreqdetailsforassessment?visa_id=${visaId}&visa_assessment_id=${assesmentId}`;

  const [post, setPost] =  useState(null);
  
  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data)
    })
  }, [])

        return(
            <>
           
            <Grid className='requirements'>
              </Grid>
              <CardContent>
                {post?.visa_requirements.map((items) =>(
                  Object.values(items.requirementByRequirementId.visa_assessment_evaluated_requirements).length === 0
                  ? 
                  <>
                      <Item className='list' color={'#D9D9D9'} marginBottom={'9px'}>
                      <Item display={'flex'} gap={'5px'} alignContent={'center'} alignItems={'center'}> <FontAwesomeIcon icon={faCircle} /> <Typography color={'#828282'}>{items.requirementByRequirementId.name_sp}</Typography> </Item>
                      <Item display={'flex'}><FontAwesomeIcon icon={faCircle} color='white' /> <Typography color={'#828282'} fontSize={'12px'}><Markup content={items.requirementByRequirementId.more_info_sp} /></Typography> </Item>
                      </Item>
                  </>
                  : <>
                      {items.requirementByRequirementId.visa_assessment_evaluated_requirements.find((items) => (items.evaluated_result === true))
                      ? <Item className='list' color={'#D9D9D9'} marginBottom={'9px'} >
                          <Item display={'flex'} gap={'5px'} alignContent={'center'} alignItems={'center'}> <FontAwesomeIcon icon={faCheck} color={'#2DD88C'} /> <Typography color={'#828282'}>{items.requirementByRequirementId.name_sp}</Typography> </Item>
                        </Item>
                      : <Item className='list' color={'#D9D9D9'} marginBottom={'9px'}>
                      <Item display={'flex'} alignContent={'center'} alignItems={'center'} gap={'5px'}> <FontAwesomeIcon icon={faCircle} /> <Typography color={'#828282'}>{items.requirementByRequirementId.name_sp}</Typography> </Item>
                      <Item display={'flex'} ><FontAwesomeIcon icon={faCircle} color='white' /> <Typography color={'#828282'} fontSize={'12px'}><Markup content={items.requirementByRequirementId.more_info_sp} /></Typography> </Item>
                      </Item>
                      }
                  </>
                ))}
              </CardContent>
            </>

        )
      
      return null

}

export default RequirementsResults 