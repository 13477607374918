import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import Item from '@mui/material/Box'
import { Link } from 'gatsby'

const BackButton = ()=>{
    return(
        <>
        <Grid paddingTop='20px' paddingBottom='20px' display='flex' flexDirection='row' gap='10px' alignItems='center'>
{/*         <FontAwesomeIcon   icon={faArrowLeft} /><Link to="/"><Typography color='text.light' >Volver a Mi Perfil</Typography> </Link> */}
        </Grid>
        </>
    )
}
export default BackButton