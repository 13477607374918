import React,{useState} from 'react'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Item from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import ContentContainer from '../../components/content-container'
import Container from '@mui/material/Container'
import { StaticImage } from 'gatsby-plugin-image'
import Modal from '@mui/material/Modal'
import { useFilter, useSelect } from 'react-supabase'
import { Markup } from 'interweave';
import axios from "axios";
import './expertise.scss'

const EstructureProfile = () => {
    return(
        <>
        <Container maxWidth="lg" >
        <Box className='triangle' ></Box>
         </Container>
    <Box className='response-box'>
        <Container maxWidth="lg" >
        <Typography className='title'>
            Daniela, este es tu análisis migratorio en base a tus respuestas
        </Typography>
        <Typography className='sub-title'>
            Tu objetivo
        </Typography>
        <Typography variant='p'>
            <Typography className='bold' variant='p'> Trabajar </Typography>  en los Estados Unidos 
        </Typography>
        <Typography className='sub-title-2'>
            Tu primer paso para acceder a visas laborales
        </Typography>
        <Typography>
            Daniela, para acceder a visas laborales tu primer paso es conseguir una oferta laboral. Es decir, necesitas un empleador en los Estados Unidos dispuesto a contratarte antes de emigrar. 
            <Typography variant='p' > <Link className='link'>  Aquí </Link></Typography>  te enseñamos como conseguir una oferta laboral. 
        </Typography>
        </Container>
    </Box>
        </>
        
    )
}

const ExpertProfile = (props) => {
    const  storysummary = props.data
    const  storysummarywname = storysummary.toString().replace('[$name]', 'Gerson')
        return(
            <>
            <Box >
            <Container maxWidth="lg" >
                <Grid maxWidth={'lg'} display='flex' className='lawyer-profile' alignContent={'center'} alignItems='center' marginTop={'20px'} gap='15px' marginBottom={'20px'}>
                    <Item>
                    <StaticImage 
                      src="../../images/lawman.png"
                      loading="eager"
                      width={111}
                      quality={95}
                      formats={['auto', 'webp', 'avif']}
                      alt="logo"
                    />
                    </Item>
                    <Item>
                        <Typography fontWeight={'700'}>
                            Jorge Perez 
                        </Typography>
                        <Typography fontStyle={'italic'}>
                            Experto Migratorio 
                        </Typography>
                    </Item>
                </Grid>
                </Container>
            </Box>
            <Container maxWidth="lg" >
                <Box className='triangle' ></Box>
            </Container>
            <Box className='response-box'>
                <Container maxWidth="lg" >
                    {
                        props.data?.map((item) =>(
                        <>
                        <Typography className='sub-title'>
                            {item.title?.replace('[$name]', props.username)}
                        </Typography>
                        <Typography>
                           {/*  {item.content?.toString().replace('[$br]', '</br>')} */}
                           {item.content
                           ? Object.values(item.content).map((contentItem) => (
                            <> 
                              <Typography marginBottom={'10px'}>
                              <Markup content={contentItem?.toString().replace('[$name]', props.username)} />
                              </Typography>
                            </>
                             ))
                            : ""
                        }
                        </Typography>
                        {
                            item.extra?.length > 0
                            ? 
                            <Box className='extra' sx={{ borderColor: (theme) => theme.palette.primary.main, borderStyle: 'solid', borderWidth: '1px' , padding: '10px', marginTop: '20px' }}>
                                <Typography color={'primary.main'}>
                                En resúmen...
                                </Typography>
                                <ul>
                            { Object.values(item.extra).map((extralist) => (
                                <>
                                <li><Markup content={extralist?.toString().replace('[$name]', props.username)} /></li>
                                </>
                            ))}
                                </ul>
                            </Box> 
   
                            : ""
                        }
                        
                        </>
                        )
                        )
                    }

                </Container>
            </Box>
            </>
        )

}

const Expertise = (props) =>{

    if(props.info){
        return (
            <>
            {props.info ? <ExpertProfile data = {props.info.result_story_json}  username = {props.info.user.first_name}/> : ""}
            </>
        )
    }
}

export default Expertise; 