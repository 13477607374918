import React,{useState} from 'react'
import { useQuery } from 'urql'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useFilter, useSelect } from 'react-supabase'
import VisasContainer from '../premium-assesment/visas-tag'
import VisaResult from '../premium-assessment-result/visa-result'
import Header from '../../components/premium-assesment/header'
import ContentContainer from '../../components/content-container'
import BackButton  from '../../components/premium-assesment/back-button'
import Expertise from '../../components/premium-assesment/expertise'
import axios from "axios";
import { Helmet } from 'react-helmet'
// Prepare our GraphQL query


const AssessmentResult = ({ visaassessmentId }) => {
  const baseURL =  `https://movigy1.hasura.app/api/rest/selectvisaassessment?id=${visaassessmentId}`;

  const [post, setPost] =  useState(null);

  const Checkpay = (props) => {
    if(props.payment > 0){
      return(
        <Helmet>
           <meta name="paid" content="true" />
        </Helmet>
      )
    }else{
      return(
        <Helmet>
        <meta name="paid" content="false" />
        </Helmet>
      )
    }
  }
  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data)
    })
  }, [])
  if(post){
    return(
    <>
    <Checkpay payment={post.visa_assessments_by_pk?.payments.length ? post.visa_assessments_by_pk?.payments.length : 0 }/>
     <Header assessment={ visaassessmentId } info={post.visa_assessments_by_pk}/>
       <ContentContainer>
         <BackButton />
       </ContentContainer>
       <Expertise visaassessmentId={ visaassessmentId} info={post.visa_assessments_by_pk}/>
       <ContentContainer>
          <VisasContainer  visas={post.visa_assessments_by_pk?.visa_assessment_evaluated_visas}/>
        {post.visa_assessments_by_pk?.visa_assessment_evaluated_visas.map((items)=> (
            <VisaResult  visadata={items.visa.id} assesmentId = {visaassessmentId}/>
        ))}
        </ContentContainer>
    </>)
  }
}

export default AssessmentResult
