import * as React from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material'
import Item from '@mui/material/Box'
import './visa-cards.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faStar as starRegular } from '@fortawesome/free-regular-svg-icons'
import { faStar as starSolid } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ProgressBar from "@ramonak/react-progress-bar";
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import RequirementsResults from '../premium-assessment-result/requirements-results'
import { Container } from '@mui/system';
import  permanente  from '../../images/permanente.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const CardModalItemList = (props) => {
  const info = props.data
  return(
    <List>
    <ListItem  className='card-list' >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Te Permite</Typography> </Item>
        <Item>
          {info.allows_applicant_study === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Estudiar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Estudiar</Box>
          }
          {info.allows_applicant_work === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Trabajar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Trabajar</Box>
          }
        </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list' >
    <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Tu cónyuge</Typography></Item>
        <Item>
        {info.allows_spouse_study === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Estudiar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Estudiar</Box>
          }
          {info.allows_spouse_work === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Trabajar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Trabajar</Box>
          }
          </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list' >
    <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Tus hijos</Typography> </Item>
        <Item>
        {info.allows_children_study === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Estudiar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Estudiar</Box>
          }
          {info.allows_children_work === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Trabajar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Trabajar</Box>
          }
          </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Estadia en USA </Typography><Typography color={'#505050'} variant='p'>
          { info.stay_time_comparison === -1 
          ? ""
          :info?.stay_time_label_sp
          }
          </Typography></Item>
        <Item>
          {
          info.stay_time_comparison === -1 
          ? <Item> <Typography variant='p' color={'#828282'}> Permanente</Typography> <img src={permanente} /></Item>
          :<ProgressBar   className='bar-demo' completed={info.stay_time_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/>
          } 
           </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Aprobación </Typography><Typography color={'#505050'} variant='p'>{info.processing_time_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={info.processing_time_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Aprobación histórica  </Typography><Typography color={'#505050'} variant='p'>{info?.approval_rate_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={info?.approval_rate_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Costo </Typography><Typography color={'#505050'} variant='p'>{info?.cost_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={info?.cost_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list aproximado'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Aproximado </Typography><Typography color={'#505050'} variant='p'>{info?.cost_estimate_sp}</Typography></Item>
        <Item> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list tarifas'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>{info.cost_estimate_description_sp} </Typography></Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list tip-movigy' color='primary.main' >
      {
        props.data?.tip 
        ? <Box component={'span'} backgroundColor='secondary.orange'>
          <Typography color='primary.main' fontWeight={'600'}><FontAwesomeIcon icon={starSolid}/> TIP MOVIGY </Typography>
          <Typography variant="body2" color="primary.main">
            {props.data.tip}
          </Typography>
          </Box>
        : ""
      }

    </ListItem>
    </List>
  )
}
const CarItemList = (props) => {
  const GetRank = (data) => {
    let content = [];
    for (let i = 0; i < 5; i++) {
      if( i < props.data.preference_rank ){
        content.push(<FontAwesomeIcon icon={starSolid} fontSize={'23px'} color='#74B6E6'/>)
      }else{
        content.push(<FontAwesomeIcon icon={starRegular} fontSize={'23px'} color='#74B6E6'/>)
      }
     
    }
    return content;
  }
  return(
    <List>
    <ListItem  className='card-list' >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Te Permite</Typography></Item>
        <Item>
        {props.study === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Estudiar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Estudiar</Box>
          }
          {props.work === true 
          ? <Box className={'tags true'} component="span"><FontAwesomeIcon icon={faCheck}/> Trabajar</Box>
          : <Box className={'tags'} component="span"><FontAwesomeIcon icon={faBan}/> Trabajar</Box>
          }
          </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Estadia en USA </Typography><Typography color={'#505050'} variant='p'>
          { props.data.stay_time_comparison === -1 
          ? ""
          :props.data?.stay_time_label_sp
          }
          </Typography></Item>
        <Item>{
          props.data.stay_time_comparison === -1 
          ? <Item> <Typography variant='p' color={'#828282'}> Permanente</Typography> <img src={permanente} /></Item>
          :<ProgressBar   className='bar-demo' completed={props.data.stay_time_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/>
          } 
        </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Aprobación </Typography><Typography color={'#505050'} variant='p'>{props.data?.processing_time_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={props.data?.processing_time_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Aprobación histórica  </Typography><Typography color={'#505050'} variant='p'>{props.data?.approval_rate_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={props.data?.approval_rate_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item><Typography variant='p' color={'#828282'}>Costo </Typography><Typography color={'#505050'} variant='p'>{props.data?.cost_label_sp}</Typography></Item>
        <Item><ProgressBar   className='bar-demo' completed={props.data?.cost_comparison} customLabel="Poco" bgColor='linear-gradient(92.46deg, #76B8E7 4.64%, #62ACE0 88.96%)' labelSize='0px'/> </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list'  >
      <Grid display={'flex'} flexDirection='row' justifyContent={'space-between'} width='100%'>
        <Item display={'flex'} alignContent={'center'} alignItems={'center'} gap={'10px'}><Typography variant='p' color={'#828282'}>Ranking Movigy  </Typography> <FontAwesomeIcon icon={faCircleQuestion} sx={{marginTop : '20px'}} fontSize={'18px'} color='#FF7A2F'/></Item>
        <Item>  <GetRank data={props.data.preference_rank} />   </Item>
      </Grid>
    </ListItem>
    <ListItem  className='card-list tip-movigy' color='primary.main' >
      {
        props.data?.tip 
        ? <Box component={'span'} backgroundColor='secondary.orange'>
          <Typography color='primary.main' fontWeight={'600'}><FontAwesomeIcon icon={starSolid}/> TIP MOVIGY </Typography>
          <Typography variant="body2" color="primary.main">
            {props.data.tip}
          </Typography>
          </Box>
        : ""
      }

    </ListItem>
    </List>
  )
}
const CardModal = (data) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    Width: 300,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const description_json = data.data.long_description_json_sp

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
    <CardContent sx={{textAlign: 'right'}}>
      <Link color={'#828282'} onClick={handleOpen}>
      Leer mas sobre esta visa
      </Link>
    </CardContent>

    <Modal
      open={open}
      onClose={handleClose}
    >
        <Card className='modal-card' >
          <Grid>
            <Item display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'} paddingTop={'40px'} paddingRight={'20px'} paddingBottom={'20px'}>
              <Button onClick={handleClose} ><FontAwesomeIcon icon={faXmark} fontSize={'23px'} color='#BEBEBE'/> </Button>
            </Item>
          </Grid>
          <Container marginBottom={'26px'}>
            <Typography variant='h2' marginBottom='26px'>{data.data.display_name_sp}</Typography>
          <Grid marginBottom={'26px'}>
          {
            description_json 
            ? Object.values(description_json).map((item) => (
             <>  
             <Item>
             <Typography className='description title' color={'primary'}> {item.title}</Typography>
             <Typography className='description description' variant='p'> {item.description}</Typography> 
           </Item>
             </>
              ))
            :""
          }
          
          </Grid>
          </Container>
          <Container> 
          <CardModalItemList data={data.data} />
          <ListItem  className='card-list button' color='primary.main' >
            <Button variant="contained" onClick={handleClose}> Entendido
            </Button>
          </ListItem>
          </Container>
      </Card>
        
    </Modal>
    </>
  )
}

const LabelIcons = (props) => {
  if(props.label === "LABORAL"){
    return  <FontAwesomeIcon icon={faSuitcase}/>
  }
  if(props.label === "INVERSIÓN"){
    return  <FontAwesomeIcon icon={faMoneyBillTrendUp}/>
  }
  if(props.label === "ESTUDIANTIL"){
    return  <FontAwesomeIcon icon={faBookOpen}/>
  }
  if(props.label === "TURISMO"){
    return  <FontAwesomeIcon icon={faPlaneDeparture}/>
  }
  if(props.label === "OTRO"){
    return  <FontAwesomeIcon icon={faUser}/>
  }
  else{
    return  <FontAwesomeIcon icon={faSuitcase}/>
  }
}

const VisaCards = (props) => {
    return(
        <Card sx={{ maxWidth: 345 }} className='card'>
          <Grid container className='header' sx={{backgroundColor:'secondary.orange'}} display='flex' flexDirection='column'>
          <CardContent>
            <Item className='tag' color={'primary.main'}>
              <LabelIcons label={props.data?.activity_goal_label_sp}/> {props.data?.activity_goal_label_sp ? props.data?.activity_goal_label_sp : "Empty" }
            </Item>
            <Item className='title'>
              <Typography variant='h3'>{props.data?.display_name_sp ? props.data?.display_name_sp  : "EMPTY"}</Typography> <Typography color="text.secondary">{ props.data?.title_short_description_sp ? props.data?.title_short_description_sp  : "EMPTY"}</Typography>  
            </Item>
            </CardContent>
          </Grid>
          <RequirementsResults  visaId = {props.data?.id} assesmentId={props.assesmentId}/>
          <CarItemList study={props.data?.allows_applicant_study} work={props.data?.allows_applicant_work} data={props.data}/>
          <CardModal data={props.data}/>
      </Card>
    )
}
export default VisaCards 