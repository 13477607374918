import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@mui/material'
import Item from '@mui/material/Box'
import { Link } from 'gatsby'
import './visas-tag.scss'
import { useQuery } from 'urql'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useFilter, useSelect } from 'react-supabase'
import { graphql, useStaticQuery } from 'gatsby'


const VisasContainer = (props)=>{
    return(
        
        <Grid container className='visas-item container'>
          {props.visas?.map((items)=> (
            <Item className='item'>
            {items.visa.display_name_sp}
            </Item>
          ))}
        </Grid>
    )
}
export default VisasContainer