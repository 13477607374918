import React,{useState} from 'react'
import { useQuery } from 'urql'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useFilter, useSelect } from 'react-supabase'
import { graphql, useStaticQuery } from 'gatsby'
import VisaCards from '../premium-assesment/visa-cards'
import axios from "axios";

// Prepare our GraphQL query


const VisaResult = ({ visadata ,assesmentId }) => {

  const baseURL =  `https://movigy1.hasura.app/api/rest/selectvisadetail?id=${visadata}`;

  const [post, setPost] =  useState(null);
  
  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data)
    })
  }, [])
  if(post){
    return(<VisaCards data={post.visas_by_pk} assesmentId = {assesmentId}/>)
  }
}

export default VisaResult
